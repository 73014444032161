import Define from "../../constants/define";
import { ClientCompanyWareHouse } from "../../models";
import { Quartet } from "../../models/Notification";

export function triangleToQuartet(triangles: ClientCompanyWareHouse[]){
    const results: Quartet[] = [];
    triangles.forEach(triangle =>{
        const item = triangle as Quartet;
        item.user_id = localStorage.getItem(Define.USER_ID) || '';
        results.push(triangle as Quartet);
    })
    return results;
}

// from the combine of user id, client id, warehouse id and company id to generate a uuid in firestore
export const quartetToDocId = (quartet: Quartet) => `${quartet.client_id}-${quartet.warehouse_id}-${quartet.company_id}-${quartet.user_id}`