import {
  AllEntrepotToUpdateSociete,
  EntrepotInterface,
  Societe,
  SocieteArrayInterface,
  SocieteCreateResponseInterface,
  SocieteGetByIdResponseInterface,
  TransfertsMailArrayInterface,
  TransfertsMailDefaultResponse,
  AuthenticationPayload,
  TransfertsMailPayload,
  FTPConfigArrayInterface,
  FTPConfigPayload,
  FTPConfigDefaultResponse,
  FTPConnectPayload
} from '../models'
import baseAxios from './clientAPI'
import Define from '../constants/define'
import { getUrlFilterParams } from '../utils'

const societeApi = {
  getAllEntrepot(payload: {
    limit: Number
    offset: Number
  }): Promise<EntrepotInterface> {
    const url = `/warehouse/warehouse-company?limit=${payload?.limit}&offset=${payload?.offset}`
    return baseAxios.get(url)
  },

  getAllSociete(payload: {}): Promise<SocieteArrayInterface> {
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const filters = {
      user_id: localStorage.getItem(Define.USER_ID) || '',
      role: localStorage.getItem(Define.ROLE) || '',
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      order: 'DESC',
      ...condition,
    }
    const url = `/warehouse/company`
    return baseAxios.get(url, { params: filters })
  },

  createSociete(payload: Societe): Promise<SocieteCreateResponseInterface> {
    const url = '/warehouse/company'
    return baseAxios.post(url, payload)
  },

  getSocieteById(payload: {
    id: string
  }): Promise<SocieteGetByIdResponseInterface> {
    const url = `warehouse/company/id/${payload.id}`
    return baseAxios.get(url)
  },

  getAllEntrepotToUpdateSociete(payload: {
    id: string
  }): Promise<AllEntrepotToUpdateSociete> {
    const url = `warehouse/company/warehouse/${payload.id}`
    return baseAxios.get(url)
  },

  deleteSocieteById(payload: { id: string }): Promise<any> {
    const url = `warehouse/company/${payload.id}`
    return baseAxios.delete(url)
  },

  deleteMultipleSociete(payload: { ids: string[] }): Promise<any> {
    const url = `warehouse/company/many`
    return baseAxios.delete(url, { data: { ids: payload.ids } })
  },

  editSociete(payload: Societe): Promise<SocieteGetByIdResponseInterface> {
    const url = 'warehouse/company/update'
    return baseAxios.post(url, payload)
  },

  getTransfersMailSociete(payload: {
    id: string
  }): Promise<TransfertsMailArrayInterface> {
    const url = `warehouse/maildatatransfer/company/${payload.id}`
    return baseAxios.get(url)
  },

  createTransfersMailSociete(
    payload: TransfertsMailPayload
  ): Promise<TransfertsMailDefaultResponse> {
    const url = `warehouse/maildatatransfer/company/${payload.company_id}`
    return baseAxios.post(url, payload)
  },

  updateTransfersMailSociete(
    payload: TransfertsMailPayload
  ): Promise<TransfertsMailDefaultResponse> {
    const url = `warehouse/maildatatransfer/${payload.config_id}`
    return baseAxios.put(url, payload)
  },

  deleteTransfersMailSociete(
    configId: string
  ): Promise<TransfertsMailDefaultResponse> {
    const url = `warehouse/maildatatransfer/${configId}`
    return baseAxios.delete(url)
  },

  checkMailServerAuthentication(
    payload: AuthenticationPayload
  ): Promise<TransfertsMailDefaultResponse> {
    const url = `email/testing/authentication`
    return baseAxios.post(url, payload)
  },
  
  getFTPConfigurationAll(payload: { id: string }): Promise<FTPConfigArrayInterface> {
    const url = `/warehouse/ftp/all/${payload.id}`;
    return baseAxios.get( url );
  },
  
  createFTPConfiguration( payload: FTPConfigPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp`
    return baseAxios.post( url, payload )
  },
  
  updateFTPConfiguration( payload: FTPConfigPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/${ payload.id }`
    return baseAxios.put( url, payload )
  },
  
  deleteFTPConfiguration( configId: string ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/${ configId }`
    return baseAxios.delete( url )
  },
  
  checkConnectionFTPConfiguration( payload: FTPConnectPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/check-connection`
    return baseAxios.post( url, payload )
  }
}

export default societeApi
