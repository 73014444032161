import React, { useEffect, useState } from 'react'
import Define from '../../constants/define';
import { UserRole } from '../../enum/UserRole';
import { collection, doc, DocumentData, onSnapshot, query, Query, Unsubscribe, where } from 'firebase/firestore';
import { db, dbName } from '../../myFirebase/firebase-config';
import { quartetToDocId, triangleToQuartet } from './func';
import authApi from '../../http/authApi';
import { NotificationBell, Quartet } from '../../models/Notification';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { pushNewEvent } from '../../redux/reducers/notificationSlice';


export default function useNotificationBell() {
  const dispatch = useDispatch();
  const [fetchedQuartets, setFetchedQuartets] = useState(false);
  // the combine of user id, client id, warehouse id and company id to generate a uuid in firestore
  const [quartets, setQuartets] = useState<Quartet[]>([])
  const [count, setCounts] = useState<number>(0);
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [notificationBells, setNotificationBells] = useState<Map<string, NotificationBell>>(new Map());
  useEffect(() => {
    if(!fetchedQuartets) return;
    if(!dbName) return;

    let unsubscribe = () => { };
    const notiQuery = query(
      collection(db, dbName),
      where("receiver_id", "==", localStorage.getItem(Define.USER_ID))
    )
    unsubscribe = onSnapshot(notiQuery, (snapshot) => {
      const docs = snapshot.docs;
      const notificationBellsClone: Map<string, NotificationBell> = cloneDeep(notificationBells)
      const notiChange: Map<string, number> = new Map();

      //get all init notif
      docs.forEach(doc => {
        const data = doc.data() as NotificationBell;
        const oldData = notificationBellsClone.get(doc.id);
        data.client_code_nom = oldData?.client_code_nom;
        data.company_code_nom = oldData?.company_code_nom;
        data.warehouse_code_nom = oldData?.warehouse_code_nom;
        notificationBellsClone.set(doc.id, data);
      })
      //handle when noti change
      snapshot.docChanges().forEach(change =>{
        const doc = change.doc;
        notiChange.set(doc.id, (doc.data() as NotificationBell).count)
      })

      notiChange.forEach((value, key)=>{
        const itsNoti = notificationBellsClone.get(key);
        if(!itsNoti) return;
        itsNoti.count = value;
        notificationBellsClone.set(key, itsNoti);
      })
      dispatch(pushNewEvent())
      setNotificationBells(notificationBellsClone);
    })

    return () => {
      unsubscribe();
    };

  }, [fetchedQuartets, quartets])

  useEffect(() => {
    if (!notificationBells) return;
    if (notificationBells?.size === 0) return;
    let sum = 0;
    notificationBells.forEach((value) => {
      sum += value.count;
    })
    setCounts(sum);
  }, [notificationBells])
  useEffect(()=>{
    const clientId = localStorage.getItem(Define.CHOOSING_CLIENT);
    const companyId = localStorage.getItem(Define.CHOOSING_COMPANY);
    const warehouseId = localStorage.getItem(Define.CHOOSING_WAREHOUSE);
    const userId = localStorage.getItem(Define.USER_ID)
    if(!clientId || !companyId || !warehouseId || !userId) return;

    const  currentId =  quartetToDocId({
      client_id: clientId,
      company_id: companyId,
      warehouse_id: warehouseId,
      user_id: userId
    })
    const currentNoti = notificationBells.get(currentId);
    if(!currentNoti) return;
    setCurrentCount(currentNoti.count);
  }, [notificationBells])

  useEffect(() => {
    const notificationBellsClone = cloneDeep(notificationBells);
    authApi.getMe().then(res => {
      const triangles = res.data.entry.client_warehouse_company;
      const role = res.data.entry.role;
      if(role && role !== UserRole.SUPERADMIN){
        const quartets = triangleToQuartet(triangles);
        quartets.forEach(quartet => {
          notificationBellsClone.set(quartetToDocId(quartet), {
            count: 0,
            receiver_id: quartet.user_id,
            ...quartet
          })
        })
        setNotificationBells(notificationBellsClone)
        setQuartets(quartets)
      }
    })
      .catch(err => console.log("getMeErr: ", err))
      .finally(() => {
        setFetchedQuartets(true);
      })
  }, [])


  return {
    quartets,
    count,
    notificationBells,
    currentCount
  }

}


